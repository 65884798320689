import React, { useEffect, useRef } from "react";
import gsap from "gsap";
import "./LoadingScreen.css";

function LoadingScreen() {
  const notesRef = useRef(null);

  useEffect(() => {
    const notes = notesRef.current.children;

    gsap.to(notes, {
      duration: 2,
      autoAlpha: 0,
      stagger: {
        each: 0.5,
        repeat: -1,
      },
      ease: "power1.inOut",
    });
  }, []);

  return (
    <div className="loadingScreen">
      <div className="loadingText" ref={notesRef}>
        <span>🎵</span>
        <span>🎵</span>
        <span>🎵</span>
      </div>
    </div>
  );
}

export default LoadingScreen;
