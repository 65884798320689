import React, { useState, useEffect } from "react";
import "./App.css";
import Contact from "./components/Contact/Contact";
import FluteViewer from "./components/Flute/Flute";
import LoadingScreen from "./components/LoadingScreen/LoadingScreen";

import { onSceneReady, onRender } from "./utils/sceneUtils";

function App() {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    document.title = "Torso Studio";
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <FluteViewer
          antialias
          onSceneReady={(scene) => onSceneReady(scene, setIsLoaded)}
          onRender={onRender}
          id="my-canvas"
        />
        {isLoaded ? <Contact /> : <LoadingScreen />}
      </header>
    </div>
  );
}

export default App;
