import React, { useEffect } from "react";
import "./Contact.css";

const ContactComponent = ({ onMouseEnter, onMouseLeave }) => {
  useEffect(() => {
    // After the component is mounted, add the 'fade-in' class to trigger the animation
    const nameElement = document.querySelector(".name");
    const contactElement = document.querySelector(".contact");
    if (nameElement && contactElement) {
      nameElement.classList.add("fade-in");
      contactElement.classList.add("fade-in");
    }
  }, []);

  return (
    <div className="contact-container">
      <div className="intercept">
        <h2 className="name">
          Torso
          <br /> Studio
        </h2>
      </div>
      <p className="contact">
        <a
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          href="mailto:info@torso-studio.com"
          className="contact-link"
        >
          Contact
        </a>
      </p>
    </div>
  );
};

export default ContactComponent;
