import "@babylonjs/loaders/glTF";
import * as BABYLON from "@babylonjs/core";
import { gsap } from "gsap";

export const createCustomLoadingScreen = (scene) => {
  const loadingScreenDiv = document.createElement("div");
  loadingScreenDiv.id = "loadingScreen";
  loadingScreenDiv.style.backgroundColor = "#eff2f4";
  loadingScreenDiv.style.position = "absolute";
  loadingScreenDiv.style.width = "100%";
  loadingScreenDiv.style.height = "100%";
  loadingScreenDiv.style.textAlign = "center";

  const loadingText = document.createElement("div");
  loadingText.id = "loadingScreenPercent";
  loadingText.style.color = "#FFF";
  loadingText.style.fontSize = "3em";
  loadingText.style.position = "relative";
  loadingText.style.top = "50%";

  loadingScreenDiv.appendChild(loadingText);
  document.body.appendChild(loadingScreenDiv);

  scene.getEngine().loadingScreen = {
    displayLoadingUI: function () {
      // Show custom loading screen
      loadingScreenDiv.style.display = "block";
    },
    hideLoadingUI: function () {
      // Hide custom loading screen
      loadingScreenDiv.style.display = "none";
    },
  };
};

export const createArcCamera = (scene) => {
  const alpha = Math.PI / 2;
  const beta = Math.PI / 4;
  const radius = 10;

  const camera = new BABYLON.ArcRotateCamera(
    "camera1",
    alpha,
    beta,
    radius,
    BABYLON.Vector3.Zero(),
    scene
  );
  camera.attachControl(scene.getEngine().getRenderingCanvas(), true);

  camera.fov = 1.4;

  return camera;
};

export const createUniversalCamera = (scene) => {
  const camera = new BABYLON.UniversalCamera(
    "camera1",
    new BABYLON.Vector3(0, 0, -10),
    scene
  );

  camera.setTarget(BABYLON.Vector3.Zero());
  camera.attachControl(scene.getEngine().getRenderingCanvas(), true);

  camera.fov = 1.2;
  // camera.fov = 0.5;

  return camera;
};

export const createHemisphericLight = (scene) => {
  const light = new BABYLON.HemisphericLight(
    "light",
    new BABYLON.Vector3(0, 1, 0),
    scene
  );
  light.intensity = 1.5;
};

export const createPlane = (scene) => {
  // Create the plane with MeshBuilder
  const plane = BABYLON.MeshBuilder.CreatePlane(
    "plane",
    { width: 2, height: 2.5 },
    scene
  );

  // Create a material for the plane
  const planeMaterial = new BABYLON.StandardMaterial("planeMaterial", scene);
  planeMaterial.diffuseColor = new BABYLON.Color3(0.1, 1, 0.1);
  planeMaterial.alpha = 0; // Set alpha to 0 to make the plane transparent

  // Assign the material to the plane
  plane.material = planeMaterial;

  // Position and orient the plane to face the user
  plane.position = new BABYLON.Vector3(0, -0.7, 0); // Change the position values as needed
  plane.rotation = new BABYLON.Vector3(0, 0, 0); // Rotating the plane by 90 degrees around the X-axis

  return plane;
};

export const detectMouseOverGround = (scene, fluteMesh, ground) => {
  const glowLayer = new BABYLON.GlowLayer("glowLayer", scene);
  glowLayer.addIncludedOnlyMesh(fluteMesh);
  glowLayer.intensity = 0;
  glowLayer.customEmissiveColorSelector = (mesh, subMesh, material, result) => {
    if (mesh === fluteMesh) {
      result.set(1, 0.84, 0); // Goldish color
    } else {
      result.set(0, 0, 0);
    }
  };

  let isGlowing = false;

  scene.onPointerMove = function (event) {
    const pickResult = scene.pick(scene.pointerX, scene.pointerY);
    if (pickResult.hit && pickResult.pickedMesh === ground) {
      if (!isGlowing) {
        isGlowing = true;
        gsap.to(glowLayer, {
          duration: 0.5,
          intensity: 0.35,
        });
      }
    } else {
      if (isGlowing) {
        isGlowing = false;
        gsap.to(glowLayer, {
          duration: 1.2,
          intensity: 0,
        });
      }
    }
  };
};

export const detectMouseOverPlane = (scene, fluteMesh, plane) => {
  const glowLayer = new BABYLON.GlowLayer("glowLayer", scene);
  glowLayer.addIncludedOnlyMesh(fluteMesh);
  glowLayer.intensity = 0;
  glowLayer.customEmissiveColorSelector = (mesh, subMesh, material, result) => {
    if (mesh === fluteMesh) {
      result.set(1, 0.84, 0); // Goldish color
    } else {
      result.set(0, 0, 0);
    }
  };

  let isGlowing = false;

  plane.actionManager = new BABYLON.ActionManager(scene);

  const fadeIn = new BABYLON.Animation(
    "fadeIn",
    "intensity",
    30,
    BABYLON.Animation.ANIMATIONTYPE_FLOAT,
    BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT
  );
  const fadeOut = new BABYLON.Animation(
    "fadeOut",
    "intensity",
    30,
    BABYLON.Animation.ANIMATIONTYPE_FLOAT,
    BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT
  );

  // Triggered when the mouse enters the plane
  plane.actionManager.registerAction(
    new BABYLON.ExecuteCodeAction(
      BABYLON.ActionManager.OnPointerOverTrigger,
      () => {
        if (!isGlowing) {
          isGlowing = true;
          scene.getEngine().getRenderingCanvas().style.cursor = "default";

          // Create animation keys for fade-in effect
          const keys = [];
          keys.push({ frame: 0, value: 0 });
          keys.push({ frame: 30, value: 0.35 });
          fadeIn.setKeys(keys);

          // Run animation
          glowLayer.animations = [];
          glowLayer.animations.push(fadeIn);
          scene.beginAnimation(glowLayer, 0, 30, false);
        }

        // Set cursor to arrow
      }
    )
  );

  // Triggered when the mouse leaves the plane
  plane.actionManager.registerAction(
    new BABYLON.ExecuteCodeAction(
      BABYLON.ActionManager.OnPointerOutTrigger,
      () => {
        if (isGlowing) {
          isGlowing = false;

          // Create animation keys for fade-out effect
          const keys = [];
          keys.push({ frame: 0, value: 0.35 });
          keys.push({ frame: 30, value: 0 });
          fadeOut.setKeys(keys);

          // Run animation
          glowLayer.animations = [];
          glowLayer.animations.push(fadeOut);
          scene.beginAnimation(glowLayer, 0, 30, false);
        }
      }
    )
  );
};

// const moveFluteOnYAxis = (mesh, scene) => {
//   const animation = new BABYLON.Animation(
//     "moveFlute",
//     "position.y",
//     30,
//     BABYLON.Animation.ANIMATIONTYPE_FLOAT,
//     BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT
//   );

//   const keys = [];
//   keys.push({
//     frame: 0,
//     value: 0.45,
//   });
//   keys.push({
//     frame: 60,
//     value: 0.65,
//   });

//   animation.setKeys(keys);

//   // Add elastic easing function
//   const easingFunction = new BABYLON.ElasticEase(1);
//   easingFunction.setEasingMode(BABYLON.EasingFunction.EASINGMODE_EASEOUT);
//   animation.setEasingFunction(easingFunction);

//   mesh.animations.push(animation);

//   scene.beginAnimation(mesh, 0, 60, false);
// };

const loadFluteMesh = (scene, camera, plane, setIsLoaded) => {
  BABYLON.SceneLoader.ImportMesh(
    "",
    "https://raw.githubusercontent.com/MariuszUrban/Models/main/Flute_Final/",
    "FLUTE_final.glb",
    scene,
    (meshes) => {
      let center = BABYLON.Vector3.Zero();

      meshes.forEach((mesh) => {
        if (mesh instanceof BABYLON.AbstractMesh) {
          center.addInPlace(mesh.getBoundingInfo().boundingBox.centerWorld);

          mesh.position = new BABYLON.Vector3(0.25, 0.65, 0);
          scene.addMesh(mesh);
          detectMouseOverPlane(scene, mesh, plane);

          // Fade in animation
          // fadeInMesh(mesh, scene);

          // Move on Y-axis animation
          // moveFluteOnYAxis(mesh, scene);
        }
      });

      center.scaleInPlace(1 / meshes.length);
      camera.setTarget(center);

      setIsLoaded(true);
    }
  );
};

// sceneUtils.js
export const onSceneReady = async (scene, setIsLoaded) => {
  const camera = createUniversalCamera(scene);
  const plane = createPlane(scene);

  const originalCameraPosition = camera.position.clone();
  const originalCameraTarget = camera.getTarget().clone();

  createHemisphericLight(scene);

  scene.clearColor = BABYLON.Color4.FromHexString("#eff2f4");

  // Load the mesh
  loadFluteMesh(scene, camera, plane, setIsLoaded);

  // Listen to pointer events
  scene.onPointerObservable.add((pointerInfo) => {
    if (pointerInfo.type === BABYLON.PointerEventTypes.POINTERWHEEL) {
      pointerInfo.event.preventDefault();
    }
    if (pointerInfo.type === BABYLON.PointerEventTypes.POINTERUP) {
      // Animate camera position and target
      gsap.to(camera.position, {
        duration: 1,
        x: originalCameraPosition.x,
        y: originalCameraPosition.y,
        z: originalCameraPosition.z,
        onUpdate: () => camera.getViewMatrix(),
      });

      gsap.to(camera.getTarget(), {
        duration: 1,
        x: originalCameraTarget.x,
        y: originalCameraTarget.y,
        z: originalCameraTarget.z,
        onUpdate: () => camera.setTarget(camera.getTarget()),
      });
    }
  });
};

export const onRender = (scene) => {};
